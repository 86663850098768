export const APP_TITLE = 'X10'
export const TRADING_VIEW_VERSION = '27_005'

export const CSS_STATIC_COLUMN_WIDTH_VAR = '--x10-static-column-width'
export const EMPTY_CELL_VALUE = '-'
export const LEVERAGE_SYMBOL = 'x'

export const DEFAULT_ACCOUNT_INDEX = 0
export const DEFAULT_MARKET = 'BTC-USD'
export const MARKET_ORDER_PRICE_SLIPPAGE_PCT = 0.05

export const USDC_BALANCE_PRECISION = 2
export const UNDEFINED_PRECISION = 3

export const SENTRY_DSN =
  'https://055f22ccf8450c5f81446f489707dead@o4506942082383872.ingest.us.sentry.io/4506942084087808'

export const WALLET_CONNECT_PROJECT_ID = '02ebfb7aed5b851e950aa365b045a7aa'
export const PARTICLE_PROJECT_ID = '91f62fbc-fa44-42a5-9afc-5ffb6775a2a9'
export const PARTICLE_CLIENT_KEY = 'cQoo0o37ix8xcAgrTNf0COzr4J044teUtz0hhEet'
export const PARTICLE_APP_ID = 'facbbd9d-ccca-46a6-966a-b41f277628fb'

export const API_DOCS_URL = 'https://x10xchange.github.io/x10-documentation/'
export const PYTHON_SDK_URL =
  'https://github.com/x10xchange/python_sdk/blob/main/README.md'
export const LANDING_URL = 'https://x10.exchange'
export const TESTNET_URL = 'https://testnet.x10.exchange'
export const MAINNET_URL = 'https://app.x10.exchange'

export const TWITTER_URL = 'https://twitter.com/X10_exchange'
export const TELEGRAM_URL = 'https://t.me/x10updates'
export const DISCORD_URL = 'https://discord.gg/x10exchange'
