import * as z from 'zod'

import { EnvConfig } from '@x10/lib-core/utils'

const clientConfig = EnvConfig(
  z
    .object({
      nodeEnv: z.enum(['development', 'test', 'production']),
      runtimeEnv: z.enum(['local', 'dev', 'testnet', 'prod']),
      buildId: z.string(),
      app: z.object({
        googleAnalyticsId: z.string().optional(),
        intercomAppId: z.string().optional(),
        amplitudeApiKey: z.string().optional(),
      }),
      restApiHost: z.string().endsWith('.x10.exchange'),
    })
    .transform((data) => {
      return {
        ...data,
        isLocalOrDevRuntimeEnv: data.runtimeEnv === 'local' || data.runtimeEnv === 'dev',
        isTestnetRuntimeEnv: data.runtimeEnv === 'testnet',
        isProdRuntimeEnv: data.runtimeEnv === 'prod',
      }
    }),
  {
    nodeEnv: process.env.NODE_ENV,
    runtimeEnv: process.env.NEXT_PUBLIC_RUNTIME_ENV,
    buildId: process.env.NEXT_PUBLIC_BUILD_ID,
    app: JSON.parse(process.env.NEXT_PUBLIC_APP_CLIENT_CONFIG || '{}'),
    restApiHost: process.env.NEXT_PUBLIC_REST_API_HOST,
  },
)

export const getClientConfig = clientConfig.get

const serverConfig = EnvConfig(
  z.object({
    runtimeEnv: z.string(),
    httpBasicAuth: z.string().default(':'),
  }),
  {
    runtimeEnv: process.env.RUNTIME_ENV,
    httpBasicAuth: process.env.HTTP_BASIC_AUTH,
  },
)

export const getServerConfig = serverConfig.get

export const IS_PROD_BUILD_ENV = process.env.NODE_ENV === 'production'
