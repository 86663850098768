var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"83eefcff927ba98f20c514e5f9c390b18909aa2c"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'

import { getClientConfig } from '@src/domain/core/config/env'
import { SENTRY_DSN } from '@src/domain/core/config/static'

const config = getClientConfig()

if (config.nodeEnv === 'production') {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: config.runtimeEnv,
    release: config.buildId,
    tracesSampleRate: 1,
    debug: false,
    denyUrls: ['anonymous', 'chrome-extension://', 'safari-extension://'],
    ignoreErrors: [
      // When auth is lost and the user tries to interact with the app
      'Request failed with status code 401',
      // Seems related to the dialogs with videos we are showing: https://goo.gl/LdLk22
      'AbortError: The play() request was interrupted',
      'The fetching process for the media resource was aborted by the user agent',
      // Root cause is unknown for now
      "NotFoundError: Failed to execute 'removeChild' on 'Node'",
      // Caused by Market Maker accounts (3019, 3020 on TESTNET)
      // 600 orders per book (creating 3k orders per 10s -> 300 orders/s)
      // FIXME: Must be investigated
      //  https://linear.app/ex10/issue/X10-1624/profile-performancememory-consumption
      'Maximum update depth exceeded',
    ],
  })
}
